import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  public filterObjectOverlay$$ = new BehaviorSubject({
    states: [],
    jobs: [],
    salaryRange: {
      from: null,
      to: null,
      type: ''
    }
  });
  constructor(private http: HttpClient, private router: Router) { }
  apiUrl = environment.forecastingService;

  getTotalJobPostings(body): Observable<any> {
    return this.http.post(this.apiUrl + '/analytics/job/postings/total',body);
  }

  getTotalHiringCompany(body): Observable<any> {
    return this.http.post(this.apiUrl + '/analytics/job/hiring/companies/total',body);
  }

  getAvgSalary(body): Observable<any> {
    return this.http.post(this.apiUrl + '/analytics/job/hiring/median_salary',body);
  }

  getAvgHourlyRate(body): Observable<any> {
    return this.http.post(this.apiUrl + '/analytics/job/hiring/avg_hourly_pay',body);
  }

  top10workforceDemands(body): Observable<any> {
    return this.http.post(this.apiUrl + '/analytics/job/top/10/workforce',body);
  }

  top10ITRoles(body): Observable<any> {
    return this.http.post(this.apiUrl + '/analytics/job/top/10/it/roles',body);
  }

  top10HealthcareRoles(body): Observable<any> {
    return this.http.post(this.apiUrl + '/analytics/job/top/10/healthcare/roles',body);
  }
  getJobs(body): Observable<any> {
    return this.http.post(this.apiUrl + '/analytics/job/jobcategories',body);
  }

  getComps(): Observable<any> {
    return this.http.get(this.apiUrl + '/analytics/job/companies');
  }
  getJobLabels(body): Observable<any> {
    return this.http.post(this.apiUrl + '/analytics/job/labels',body);
  }
  getIndustries(): Observable<any> {
    return this.http.get(this.apiUrl + '/analytics/job/industries');
  }
  getStates(): Observable<any> {
    return this.http.get(this.apiUrl + '/analytics/job/states');
  }



}
